import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAvatar.figmaUrl.ios} codeUrl={checklists.componentAvatar.codeUrl.ios} checklists={checklists.componentAvatar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`In General, there are three types of LGNAvatar that you can use:`}</p>
    <ul>
      <li parentName="ul">{`Image Avatar`}</li>
      <li parentName="ul">{`Icon Avatar`}</li>
      <li parentName="ul">{`Initials Avatar`}</li>
    </ul>
    <p>{`These avatars have five sizes: `}<inlineCode parentName="p">{`.xxs`}</inlineCode>{`, `}<inlineCode parentName="p">{`.xs`}</inlineCode>{`, `}<inlineCode parentName="p">{`.sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`.md`}</inlineCode>{`, `}<inlineCode parentName="p">{`.lg`}</inlineCode>{`. Also, these avatars can have a backgroundColor that you can define. How to import your theme: Legion iOS UIKit offers four themes: `}<inlineCode parentName="p">{`ThemeAGR`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeIHS`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeLGN`}</inlineCode>{`, and `}<inlineCode parentName="p">{`ThemeMyTEnS`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`LGNAvatar have 3 variants component:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Initials`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-lg.png",
              "alt": null,
              "title": "LGNAvatar Image"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-lg.png",
              "alt": null,
              "title": "LGNAvatar Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-lg.png",
              "alt": null,
              "title": "LGNAvatar Initial"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Avatar Image Local Asset`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatar = BaseLGNAvatar()
avatar.size = .lg
avatar.imageSelection = UIImage(named: "plus")
`}</code></pre>
    <h3>{`Avatar Image Async`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatar = BaseLGNAvatar()
avatar.size = .lg
avatar.imageURL = URL(string: "https://example.com/image.jpg")
`}</code></pre>
    <h3>{`Avatar Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatar = BaseLGNAvatar()
avatar.size = .lg
avatar.imageSelection = UIImage(systemName: "plus")
avatar.backgroundColorSelection = .black
avatar.iconTintSelection = .white
`}</code></pre>
    <h3>{`Avatar Initials`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatar = BaseLGNAvatar()
avatar.size = .lg
avatar.textInitials = "Telkom Indonesia"
avatar.backgroundColorSelection = .black
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`You can customize the size of the button via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` enum, where `}<inlineCode parentName="p">{`BaseLGNAvatar.Size`}</inlineCode>{` includes `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`sm`}</inlineCode>{`. `}<inlineCode parentName="p">{`xs`}</inlineCode>{` and `}<inlineCode parentName="p">{`xxs`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Initials`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-lg.png",
              "alt": null,
              "title": "LGNAvatar Image Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-lg.png",
              "alt": null,
              "title": "LGNAvatar Icon Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-lg.png",
              "alt": null,
              "title": "LGNAvatar Initials Large"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-md.png",
              "alt": null,
              "title": "LGNAvatar Image Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-md.png",
              "alt": null,
              "title": "LGNAvatar Icon Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-md.png",
              "alt": null,
              "title": "LGNAvatar Initials Medium"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-sm.png",
              "alt": null,
              "title": "LGNAvatar Image Small"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-sm.png",
              "alt": null,
              "title": "LGNAvatar Icon Small"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-sm.png",
              "alt": null,
              "title": "LGNAvatar Initials"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`XSmall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-xs.png",
              "alt": null,
              "title": "LGNAvatar Image XSmall"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-xs.png",
              "alt": null,
              "title": "LGNAvatar Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-xs.png",
              "alt": null,
              "title": "LGNAvatar Initials XSmall "
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`XXSmall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-image-xxs.png",
              "alt": null,
              "title": "LGNAvatar Image XXSmall"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-icon-xxs.png",
              "alt": null,
              "title": "LGNAvatar IconXXSmall"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-uikit/ios-uikit-avatar-initials-xxs.png",
              "alt": null,
              "title": "LGNAvatar Initials XXSmall"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let avatar = BaseLGNAvatar()
avatar.size = .lg
avatar.imageSelection = UIImage(named: "plus")
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      